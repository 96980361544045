import React from "react";
import pageStyle from "../../assets/css/page.module.css";
import workStyle from "../../assets/css/work.module.css";
import style from "../../assets/css/main.module.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HeroPage from "../../components/hero.page";
import Hamburger from "../../components/hamburger";
import { Link } from "gatsby";
import SEO from '../../components/seo'

import imgHeader from "../../assets/img/realisations/teag_0.jpg";

export default () => (
  <div id="top">
    <Menu />
    <SEO
    title="Touch English And Go | Agence digitale | MDS Digital Agency"
    description="MDS s’est chargé de la refonte, du développement de formations et tests en ligne pour le site web de Touch English And Go."
    keywords="Touch English And Go"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHeader} />
    <div className={workStyle.info}>

      <div className={workStyle.info_cell}>
        <h3>Services</h3>
        <span className={workStyle.badge}><Link to="/services/website/">Création de site web</Link></span>
          <span className={workStyle.badge}><Link to="/services/referencement/">Référencement</Link></span>
          <span className={workStyle.badge}><Link to="/services/developpement/">Développement</Link></span>
      </div>
      <div className={workStyle.info_cell}>
      <h3>Secteur</h3>
        <p>Cours en ligne</p>
        <h3>Localisation</h3>
        <p>Mons</p>

   
      </div>
      <div className={workStyle.info_cell}>
        <h3>Lien </h3>
        <p>
          <a
          className={workStyle.external_link}
            href="https://www.touch-english-and-go.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            touch-english-and-go.com
          </a>
        </p>
      </div>
    </div>

    <article className={pageStyle.article}>
      <h2>Touch English And Go</h2>
      <p>
      Les pilotes de ligne du monde entier suivent les <strong>formations</strong> et les <strong>tests</strong> de niveau d’anglais que MDS a mis <strong>en ligne</strong> pour Touch English and Go.      
      </p>
      <p> 
      Chaque participant crée un compte sécurisé dans lequel sont enregistrés ses résultats et ses achats.
      </p>
      <p>
      Nous <strong>maintenons à jour</strong> ce site développé en <strong>2 langues</strong> et veillons au bon fonctionnement de son module e-commerce.
      </p>
      <p>
      Nous avons, par ailleurs, procédé à une révision de textes existants sur le site Touch English And Go, ce qui nous a permis d’établir une stratégie SEO sur mesure pour la société.
      </p>


      <div className={pageStyle.cta}>
      <Link to="/contact/">Contactez-nous pour en savoir plus</Link>
      </div>
    </article>
    <Footer />
  </div>
);
